import React, { Component } from 'react';

export default function Logo() {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496.23 573">
			<g>
				<polygon points="248.12,0 0,143.25 0,429.75 84.62,478.6 84.62,381.19 84.36,381.05 84.36,191.96 248.12,97.41 411.87,191.96 411.87,381.05 248.12,475.59 166.62,428.54 166.62,525.95 248.12,573 496.23,429.75 496.23,143.25 	" />
			</g>
			<g>
				<polygon points="329.99,333.77 329.99,239.23 248.12,191.96 166.24,239.23 166.24,333.77 248.12,381.05 	" />
			</g>
		</svg>
	);
}
