import React, { Component, useMemo, useState } from 'react';

import handleFetchResponse from '../../helpers/handleFetchResponse';

export default function SteamOnline() {
	const [inGame, setInGame] = useState(0);
	const [online, setOnline] = useState(0);
	const [onlineTotal, setOnlineTotal] = useState(0);

	useMemo(() => {
		fetch(`${process.env.REACT_APP_STEAM_API_HOSTNAME}/api/steam/group/p0mp`)
			.then(handleFetchResponse)
			.then(data => {
				setOnline(data.statistics.membersOnline);
				setOnlineTotal(data.statistics.membersTotal);
				setInGame(data.statistics.inGame);
			})
			.catch(console.error);
	}, [inGame, online, onlineTotal]);

	return (
		<span>
			{online}/{onlineTotal} online, {inGame} in-game
		</span>
	);
}
