import React from 'react';
import Countdown from 'react-countdown';

const Completionist = () => <span>I'm on vacation!</span>;

export default function Travel() {
	return (
		<>
			<h2>Days before my vacation</h2>
			<section>
				<Countdown date={new Date(2023, 5, 20, 13, 0, 0)}>
					<Completionist />
				</Countdown>
			</section>
		</>
	);
}
