import React, { Component, useMemo, useState } from 'react';

import handleFetchResponse from '../../helpers/handleFetchResponse';

export default function DiscordOnline() {
	const [inGame, setInGame] = useState(0);
	const [online, setOnline] = useState(0);

	useMemo(() => {
		fetch(`https://discordapp.com/api/guilds/300655148923879424/widget.json`)
			.then(handleFetchResponse)
			.then(data => {
				setInGame(data.members.filter((member: { game: any; }) => member.game).length);
				setOnline(data.members.length);
			})
			.catch(console.error);
	}, [inGame, online]);

	return (
		<span>
			{online} online, {inGame} in-game
		</span>
	);
}
