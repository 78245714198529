import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './Home';
import Layout from './Layout';
import NoMatch from './NoMatch';
import Travel from './Travel';

import '../styles.css';

export default function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
				<Route path="bahrain" element={<Travel />} />
				<Route path="*" element={<NoMatch />} />
			</Route>
		</Routes>
	);
}
