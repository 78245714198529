import React from 'react';

import Header from './Header';

import DiscordOnline from './services/DiscordOnline';
import SteamOnline from './services/SteamOnline';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSteam, faTeamspeak, faDiscord, faSlack } from '@fortawesome/free-brands-svg-icons';

export default function Home() {
	return (
		<>
			<section>
				<h1>community</h1>
				<p>
					<FontAwesomeIcon icon={faDiscord} size="lg" />{' '}
					<a href="https://discord.gg/4M5BxyY" rel="noopener noreferrer" target="_blank">
						discord
					</a>
				</p>
				<p>
					<FontAwesomeIcon icon={faSteam} size="lg" />{' '}
					<a
						href="https://steamcommunity.com/groups/p0mp"
						rel="noopener noreferrer"
						target="_blank"
					>
						steam
					</a>
				</p>
				<p>
					<FontAwesomeIcon icon={faTeamspeak} size="lg" />{' '}
					<a href="ts3server://teamspeak.p0mp.com">teamspeak</a>
				</p>
			</section>
			<section>
				<h1>online players</h1>
				<p>
					<FontAwesomeIcon icon={faDiscord} size="lg" /> <DiscordOnline />
				</p>
				<p>
					<FontAwesomeIcon icon={faSteam} size="lg" /> <SteamOnline />
				</p>
			</section>
			<section>
				<h1>game servers</h1>
				<p>Currently not running.</p>
			</section>
		</>
	);
}
